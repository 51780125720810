<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";
import vueSeamlessScroll from 'vue-seamless-scroll'

import Navbar from "@/views/new-view/components/navbar";
// import Navbar2 from "@/components/navbar2";
import Switcher from "@/components/switcher";
// import Footer from "@/components/footer";
import FooterBar from "@/views/new-view/components/footerBar";
import homeApi from "@/apis/homeApi";
import indexApi from "@/apis/indexApi";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
import Plans from "@/components/plans";
import Testimonial from "@/components/testimonial";
import date from '../date';

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/referral' }
    ]
  },
  data() {
    return {
      url: "",
      imageLoad: true,
      wallet: undefined,
      dashBoard: undefined,
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 10
      },
      options: {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      huadon: {
        lastDepositList: undefined,
        lastWithdrawList:undefined
      },
      imagesPranters: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ],
      payments: [
        "/images/crypto/bitcoin.png",
        "/images/crypto/bitcoin-cash.png",
        "/images/crypto/litecoin.png",
        "/images/crypto/ethereum.png",

        "",
        "/images/visaicon.jpg",
      ],
      featuresData: [
        {
          icon: "uil uil-rocket",
          title: "Advance Hardware",
          description:
              "We are using the latest hardware to ensure a high speed mining experience in a cost-effective manner.",
        },
        {
          icon: "uil uil-rss",
          title: "99.97%",
          description:
              "Uptime",
        },
        {
          icon: "uil uil-bitcoin-circle",
          title: "Instant Withdrawal",
          description:
              "You can withdraw your mined bitcoin instantly once you reach the minimum payment threshold, without any delays or freezing!",
        },
        {
          icon: "uil uil-head-side-cough",
          title: "Customer support 24/7",
          description:
              "Dedicated and professional customer support services available round-the-clock.",
        },
        {
          icon: "uil uil-bolt",
          title: "",
          description:
              "Mining starts immediately after payment",
        },
        {
          icon: "uil uil-wallet",
          title: "",
          description:
              "No hidden fees or commission. Everything is visible to you.",
        },
        {
          icon: "uil uil-bitcoin-alt",
          title: "",
          description:
              "Supports USDT, BTC, ETH, and LTC wallet address withdrawal",
        },
        {
          icon: "uil uil-processor",
          title: "",
          description:
              "No need to buy equipment",
        }
      ],
      testimonialData: [
        {
          id: 1,
          profile: "images/client/01.jpg",
          message:
              "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
          name: "Thomas Israel",
          designation: "C.E.O",
        },
        {
          id: 2,
          profile: "images/client/02.jpg",
          message:
              "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
          name: "Barbara McIntosh",
          designation: "M.D",
        },
        {
          id: 3,
          profile: "images/client/03.jpg",
          message:
              "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
          name: "Carl Oliver",
          designation: "P.A",
        },
        {
          id: 4,
          profile: "images/client/04.jpg",
          message:
              "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
          name: "Christa Smith",
          designation: "Manager",
        },
        {
          id: 5,
          profile: "images/client/05.jpg",
          message:
              "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
          name: "Dean Tolle",
          designation: "Developer",
        },
        {
          id: 6,
          profile: "images/client/05.jpg",
          message:
              "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
          name: "Jill Webb",
          designation: "Designer",
        },
      ],
      bottom: ""
    }
  },
  components: {
    Navbar,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    // Footer,
    FooterBar,
    HelpCircleIcon,
    Plans,
    Features,
    Testimonial,
    // Navbar2,
    vueSeamlessScroll
  },
  created() {
  },
  methods: {
    getDate(d) {
      return date.getDate(d)
    },
    loadmore() {
      this.planForm.pageNum++
      //开始加载
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      //获取项目列表
      if (pages >= this.planForm.pageNum) {
        planApi.list(this.planForm, res => {
          res.data.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data)
          this.plansLoad = 0
          console.log(pages)
          console.log(this.planForm.pageNum)
          if (pages <= this.planForm.pageNum) this.plansLoad = -1//结束加载
        })
      } else {
        this.plansLoad = -1;
      }
    }
  },
};
</script>

<template>
  <div>
    <Navbar v-if="!login" />
    <Navbar v-else />

    <!-- 主页Banner -->


    <!-- 未登录主页 -->
    <section class="section pb-2">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Affiliates Program</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <!-- 一些步骤 -->
        <div class="col-12">
          <div class="sidebar__product mb--n30 ">
            <div class="row">
              <div class="col-lg-4 col-12 mt-10">
                <article  class="product__card--horizontall d-flex mb-10 " style="padding:  0.8125rem;">
                  <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                    <a class="product__card--thumbnail__link display-block" href="/auth-signup">
                      <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                           src="images/crypto/1111.png">
                    </a>
                  </div>
<!--                  <div class="col"></div>-->
                  <div class="product__card--horizontall__content">
                    <h3 class="product__card--horizontall__title product__card--horizontall__title"
                        style="display: flex;gap: 1.25rem;">
                      <img style="width:30px;height:30px;" src="../assets/img/other/no1.png">
                      <span style="font-size: 16px;"><a href="/auth-signup">Sign Up Now</a></span>
                    </h3>
                    <span class="product__card--author__text">
                      Join our community, sign up an account, get your referral link.
                    </span>
                  </div>
                </article>

              </div>
              <div class="col-lg-4 col-12 mt-10">
                <article class="product__card--horizontall d-flex mb-10 " style="padding: 0.8125rem;">
                  <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                    <a class="product__card--thumbnail__link display-block" href="/plans">
                      <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                           src="images/crypto/44444.png">
                    </a>
                  </div>
                  <div class="product__card--horizontall__content">
                    <h3 class="product__card--horizontall__title product__card--horizontall__title"
                        style="display: flex;gap: 1.25rem;">
                      <img style="width:30px;height:30px;" src="../assets/img/other/no2.png">
                      <span style="font-size: 16px;">Invite New Users</span>
                    </h3>
                    <span class="product__card--author__text">
                    Invite new users, sharing your link via social media.
                    </span>
                  </div>
                </article>

              </div>
              <div class="col-lg-4 col-12 mt-10">
                <article class="product__card--horizontall d-flex mb-30 " style="padding: 0.8125rem;">
                  <div class="product__card--horizontall__thumbnail" style="width:150px;height:150px;">
                    <a class="product__card--thumbnail__link display-block" href="/dashboard">
                      <img class="product__card--thumbnail__img " style="width:150px;height:150px;"
                           src="images/crypto/77777.png">
                    </a>
                  </div>
                  <div class="product__card--horizontall__content">
                    <h3 class="product__card--horizontall__title product__card--horizontall__title"
                        style="display: flex;gap: 1.25rem;">
                      <img style="width:30px;height:30px;" src="../assets/img/other/no3.png">
                      <span style="font-size: 16px;"><a href="/dashboard">Earn Commissions </a></span>
                    </h3>
                    <span class="product__card--author__text">
                      Get 3% bonus based on their initial investment amount.
                    </span>
                  </div>
                </article>

              </div>
            </div>

          </div>
        </div>
<!--        <el-row type="flex" class="flex-wrap" :gutter="20" justify="space-around">-->
<!--          <el-col :span="6" :xs="24" class="mt-4">-->
<!--            <div class="card work-process border-0 rounded shadow">-->
<!--              <div class="card-body">-->
<!--                <h4 class="title">Sign up Now</h4>-->
<!--&lt;!&ndash;                <p class="text-muted para">&ndash;&gt;-->
<!--                <p>-->
<!--                  Sign up an account,and get your own referral link/URL.-->
<!--                </p>-->
<!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
<!--					<li class="h1 mb-0 font-weight-bold">Step 01.</li>-->
<!--                  <li class="step-icon">-->
<!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->

<!--          <el-col :span="6" :xs="24" class="mt-4">-->
<!--            <div class="card work-process border-0 rounded shadow">-->
<!--              <div class="card-body">-->
<!--                <h4 class="title">Refer Customers</h4>-->
<!--                <p>-->
<!--                  Invite new users.Spread by website or with your link viasocial sharing.-->
<!--                </p>-->
<!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
<!--					<li class="h1 mb-0 font-weight-bold">Step 02.</li>-->
<!--                  <li class="step-icon">-->
<!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->

<!--          <el-col :span="6" :xs="24" class="mt-4">-->
<!--            <div class="card work-process border-0 rounded shadow">-->
<!--              <div class="card-body">-->
<!--                <h4 class="title">Earn Commissions</h4>-->
<!--                <p>-->
<!--                  New user signs up to Bytebus,com & You get 10% of their purchase lifetime.-->
<!--                </p>-->
<!--                <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">-->
<!--					<li class="h1 mb-0 font-weight-bold">Step 03.</li>-->
<!--                  <li class="step-icon">-->
<!--                    <i class="mdi mdi-chevron-double-right mdi-36px"></i>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
<!--          &lt;!&ndash;end col&ndash;&gt;-->
<!--          -->
<!--          &lt;!&ndash;end col&ndash;&gt;-->
<!--        </el-row>-->
        <!--end row-->
        <!-- 推荐 -->

      </div>

      <!-- 关于我们1 -->
      <div class="container mt-100 mt-60 " style="margin-bottom: 150px;">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-5" style="overflow: hidden;">
            <div class="position-relative">
              <div class="text-center text-md-left">
                <img src="images/app/friend1.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-7 mt-5 mt-sm-0" >
            <div class="section-title">
<!--              <h1 class="title mb-3">Referral Program</h1>-->
              <!--  text-muted   -->
              <p class="para-desc">
                Join our referral program (Partner Program) and get 3% bonus based on their initial investment amount! You can run it like a boss and start earning money without investing a single penny. For instance, if someone invests in a $200 plan using your referral code, you will get $6 for free. With unlimited referrals, your earning potential is limitless! So, why wait? Sign up now and start making money by sharing your referral link with friends and family.
			  </p>
              <div class="mt-4">
                <a href="/auth-login" class="btn btn-primary">JOIN US <i class="mdi mdi-chevron-right"></i></a>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>

    <FooterBar />
    <!-- Footer End -->
<!--    <Switcher />-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style scoped lang="scss">
@import '@/style/common.scss';
.seamless-warp{
  height: 300px;
  overflow: hidden;
}

.huadonlist{
  padding: 15px;
  @media (min-width: 768px) {
    padding: 50px 160px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar__product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.9375rem
  }
}

@media only screen and (max-width: 991px) {
  .sidebar__product {
    margin-top: 1.875rem
  }
}

.product__card--horizontall {
  padding: 0.75rem;
  background: #f9f9f9;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 10px;
  gap: 15px
}

//@media only screen and (min-width: 768px) and (max-width: 991px) {
//  .product__card--horizontall {
//    width: 50%
//  }
//}

@media only screen and (min-width: 768px) {
  .product__card--horizontall {
    gap: 12px
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall {
    padding: 0.8125rem
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall {
    padding:  0.9375rem;
    gap: 15px
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall {
    padding: 1.25rem;
    gap: 20px
  }
}

@media only screen and (max-width: 767px) {
  .product__card--horizontall {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
}

@media only screen and (max-width: 419px) {
  .product__card--horizontall {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.product__card--horizontall:hover .product__card--thumbnail__img {
  -webkit-transform: scale(1.09);
  transform: scale(1.09)
}

.product__card--horizontall__thumbnail {
  width: 100%;
  border-radius: 8px;
  overflow: hidden
}

@media only screen and (min-width: 440px) {
  .product__card--horizontall__thumbnail {
    width: 210px
  }
}

@media only screen and (min-width: 576px) {
  .product__card--horizontall__thumbnail {
    width: 240px
  }
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__thumbnail {
    width: 185px
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__thumbnail {
    width: 210px
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall__thumbnail {
    width: 225px
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall__thumbnail {
    width: 240px
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__thumbnail {
    width: 260px
  }
}

.product__card--horizontall__content {
  width: 100%
}

@media only screen and (min-width: 440px) {
  .product__card--horizontall__content {
    width: calc(100% - 210px)
  }
}

@media only screen and (min-width: 576px) {
  .product__card--horizontall__content {
    width: calc(100% - 240px)
  }
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__content {
    width: calc(100% - 185px)
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__content {
    width: calc(100% - 210px)
  }
}

@media only screen and (min-width: 1200px) {
  .product__card--horizontall__content {
    width: calc(100% - 225px)
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__content {
    width: calc(100% - 240px)
  }
}

@media only screen and (min-width: 1600px) {
  .product__card--horizontall__content {
    width: calc(100% - 260px)
  }
}

@media only screen and (max-width: 991px) {
  .product__card--horizontall__content .product__card--author {
    margin-bottom: 0.625rem
  }
}

@media only screen and (max-width: 991px) {
  .product__card--horizontall__content .product__card--author__text {
    font-size: 0.8125rem
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product__card--horizontall__content .product__card--btn {
    font-size: 0.875rem;
    height: 2.25rem;
    line-height: 2.25rem
  }
}

.product__card--horizontall__title {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom:  0.9375rem;
  padding-bottom:  0.9375rem
}

@media only screen and (min-width: 768px) {
  .product__card--horizontall__title {
    margin-bottom: .625rem;
    padding-bottom: .625rem
  }
}

@media only screen and (min-width: 992px) {
  .product__card--horizontall__title {
    margin-bottom: 0.8125rem;
    padding-bottom:  0.8125rem
  }
}

@media only screen and (min-width: 1366px) {
  .product__card--horizontall__title {
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
    padding-bottom:  0.9375rem
  }
}
</style>
